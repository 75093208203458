import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Layout, Menu, Dropdown, Icon, Spin, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { getToken } from '@/src/services/auth';
import usePermissions from '@/src/hooks/usePermissions';
import * as modules from '@/src/globals/permissionsModules';

import UserAvatar from '@/src/components/Users/UserAvatar';

import zeenowLogo from '@/src/assets/images/logo.svg';
import zeedogLogo from '@/src/assets/images/logo-zeedog.svg';
import CatalogIcon from '@/src/assets/images/menu/catalogo.svg';
import CustomersIcon from '@/src/assets/images/menu/clientes.svg';
import ShippingsIcon from '@/src/assets/images/menu/embarque.svg';
import ExpeditionIcon from '@/src/assets/images/menu/expedicao.svg';
import HubsIcon from '@/src/assets/images/menu/hub.svg';
import ReferralsIcon from '@/src/assets/images/menu/indicacoes.svg';
import OrdersIcon from '@/src/assets/images/menu/pedidos.svg';
import SubscriptionsIcon from '@/src/assets/images/menu/assinaturas.svg';
import PromotionsIcon from '@/src/assets/images/menu/promocoes.svg';
import ReportsIcon from '@/src/assets/images/menu/relatorios.svg';
import UsersIcon from '@/src/assets/images/menu/usuario.svg';
import DeliverersIcon from '@/src/assets/images/menu/entregadores.svg';
import SettingsIcon from '@/src/assets/images/menu/configuracoes.svg';
// import HighlightsIcon from '@/src/assets/images/menu/campanhas.svg';
// import PetsIcon from '@/src/assets/images/menu/pets.svg';
// import PricesIcon from '@/src/assets/images/menu/preco.svg';
// import RecommendationsIcon from '@/src/assets/images/menu/recomendacoes.svg';

import { logoutAccount } from '@/src/store/modules/login/slice';
import { getAccount } from '@/src/store/modules/account/slice';

import { useBreakpoint } from '@/src/context/BreakpointContext';
import { ZEENOW_STORE, ZEEDOG_STORE } from '@/src/globals/constants';

import styles from './Layout.module.scss';

const { Header, Content, Sider } = Layout;

const { SubMenu } = Menu;

const MainLayout = props => {
  const { children } = props;
  const [isCollapsed, setIsCollapsed] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const breakpoint = useBreakpoint();
  const { editPermission } = usePermissions();

  const { account } = useSelector(state => state.account);

  const handleClick = () => dispatch(logoutAccount());

  const hasToken = getToken();

  const menu = (
    <Menu>
      <Menu.Item key="password">
        <Link to="/alterar-senha">Alterar senha</Link>
      </Menu.Item>
      <Menu.Item key="logout" onClick={handleClick}>
        Sair
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    if (account) localStorage.setItem('USER_ID', account.id);
    // eslint-disable-next-line
  }, [account]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (hasToken && !account) {
      const userId = localStorage.getItem('USER_ID');
      dispatch(getAccount(userId));
    }
    if (!hasToken) {
      return history.push('/');
    }
    // eslint-disable-next-line
  }, []);

  const toggleCollapsed = () => {
    setIsCollapsed(!isCollapsed);
  };

  return account ? (
    <Layout className={styles.container}>
      <Header className={styles.header}>
        <div className={styles.logo}>
          <img
            className={styles.logoImg}
            src={ZEENOW_STORE ? zeenowLogo : zeedogLogo}
            alt={ZEENOW_STORE ? 'Logo Zee.Now' : 'Logo Zee.Dog'}
          />
          <h3 className={styles.menuTitle}>
            Portal
            {process.env.NODE_ENV !== 'production' && (
              <span>
                Ambiente
                <br />
                {process.env.REACT_APP_ENVIRONMENT_NAME}
              </span>
            )}
          </h3>
        </div>

        <div className="flex center" style={{ lineHeight: 1 }}>
          <Dropdown
            overlay={menu}
            data-test="user-menu"
            trigger={breakpoint.mobile ? ['click'] : ['hover']}
          >
            <div data-test="header-menu" className={styles.headerMenu}>
              <UserAvatar />
              {breakpoint.lg && (
                <>
                  <span style={{ marginLeft: 8 }}>{account?.name || ''} </span>
                  <Icon type="down" />
                </>
              )}
            </div>
          </Dropdown>
        </div>
      </Header>
      <Layout>
        <Sider
          width={180}
          className={
            breakpoint.fullhd && location.pathname.includes('expedicao')
              ? 'hidden'
              : ''
          }
          style={{ background: '#fff', borderRight: 0 }}
          collapsed={isCollapsed}
          collapsedWidth={40}
          theme="light"
        >
          <button
            type="button"
            className={styles.menuButton}
            onClick={toggleCollapsed}
            style={{ padding: '16px 13px' }}
            aria-label="Collapse Menu"
          >
            <Icon
              type={isCollapsed ? 'double-right' : 'double-left'}
              style={{ fontSize: '14px' }}
            />
          </button>
          <Menu
            mode="inline"
            activeKey={location.pathname}
            selectedKeys={[location.pathname]}
            style={{ borderRight: 0 }}
            inlineIndent={8}
          >
            {editPermission(modules.ORDERS) && (
              <Menu.Item key="/pedidos">
                <Link to="/pedidos">
                  <img src={OrdersIcon} alt="Orders Icon" />
                  Pedidos
                </Link>
              </Menu.Item>
            )}
            {ZEEDOG_STORE && editPermission(modules.SUBSCRIPTIONS_VIEW) && (
              <Menu.Item key="/assinaturas">
                <Link to="/assinaturas">
                  <img src={SubscriptionsIcon} alt="Orders Icon" />
                  Assinaturas
                </Link>
              </Menu.Item>
            )}
            {editPermission(modules.CUSTOMERS_VIEW) && (
              <Menu.Item key="/clientes">
                <Link to="/clientes">
                  <img src={CustomersIcon} alt="Customers Icon" />
                  Clientes
                </Link>
              </Menu.Item>
            )}
            {editPermission(modules.CATALOG) && (
              <SubMenu
                key="/categories"
                title={
                  <span>
                    <img src={CatalogIcon} alt="Catalog Icon" />
                    <span>Catálogo</span>
                  </span>
                }
              >
                <Menu.Item className="menu-left" key="/atributos">
                  <Link to="/atributos">Atributos</Link>
                </Menu.Item>
                <Menu.Item key="/categorias">
                  <Link to="/categorias">Categorias</Link>
                </Menu.Item>
                <Menu.Item key="/colecoes">
                  <Link to="/colecoes">Coleções</Link>
                </Menu.Item>
                <Menu.Item key="/corredores">
                  <Link to="/corredores">Corredores</Link>
                </Menu.Item>
                <Menu.Item key="/departamentos">
                  <Link to="/departamentos">Departamentos</Link>
                </Menu.Item>
                <Menu.Item className="menu-left" key="/destaques">
                  <Link to="/destaques">Destaques</Link>
                </Menu.Item>
                <Menu.Item className="menu-left" key="/fabricantes">
                  <Link to="/fabricantes">Fabricantes</Link>
                </Menu.Item>
                <SubMenu
                  key="/precos"
                  title="Preços"
                  className="submenu-submenu"
                  inlineIndent={0}
                >
                  <Menu.Item key="/politicas">
                    <Tooltip
                      title="Políticas Comerciais"
                      placement="right"
                      overlayClassName="menu-tooltip"
                    >
                      <Link to="/politicas">Políticas Comerciais</Link>
                    </Tooltip>
                  </Menu.Item>
                  <Menu.Item key="/tabelas">
                    <Link to="/tabelas">Tabelas</Link>
                  </Menu.Item>
                  <Menu.Item key="/precos">
                    <Tooltip
                      title="Indexação de Preços"
                      placement="right"
                      overlayClassName="menu-tooltip"
                    >
                      <Link to="/precos">Indexação de Preços</Link>
                    </Tooltip>
                  </Menu.Item>
                </SubMenu>
                <SubMenu
                  key="/produtos"
                  title="Produtos"
                  className="submenu-submenu"
                  inlineIndent={0}
                >
                  <Menu.Item key="/produtos">
                    <Link to="/produtos">Produtos</Link>
                  </Menu.Item>
                  {editPermission(modules.SKUSPETZ) && (
                    <Menu.Item key="/skus-petz">
                      <Link to="/skus-petz">SKUs Petz</Link>
                    </Menu.Item>
                  )}
                </SubMenu>
                <Menu.Item className="menu-left" key="/recomendacoes">
                  <Link to="/recomendacoes">Recomendações</Link>
                </Menu.Item>
                {/* <Menu.Item key="/tamanhos">
                  <Link to="/tamanhos">Tamanhos</Link>
                </Menu.Item> */}
              </SubMenu>
            )}
            {editPermission(modules.PROMOTIONS) && (
              <Menu.Item key="/promocoes">
                <Link to="/promocoes">
                  <img src={PromotionsIcon} alt="Promotions Icon" />
                  Promoções
                </Link>
              </Menu.Item>
            )}
            {ZEENOW_STORE && editPermission(modules.REFERRAL) && (
              <Menu.Item key="/indicacoes">
                <Link to="/indicacoes">
                  <img src={ReferralsIcon} alt="Referrals Icon" />
                  Indicações
                </Link>
              </Menu.Item>
            )}
            {editPermission(modules.GATEWAY_STORES) && (
              <SubMenu
                key="/settings"
                title={
                  <span>
                    <img src={SettingsIcon} alt="Settings Icon" />
                    <span>Configurações</span>
                  </span>
                }
              >
                <Menu.Item className="menu-left" key="/lojas-gateway">
                  <Tooltip
                    title="Lojas com Gateway de Pagamento"
                    placement="right"
                    overlayClassName="menu-tooltip"
                  >
                    <Link to="/lojas-gateway">
                      Lojas com Gateway de Pagamento
                    </Link>
                  </Tooltip>
                </Menu.Item>
              </SubMenu>
            )}
            {ZEENOW_STORE && editPermission(modules.DISPATCH_HUBS) && (
              <Menu.Item key="/hubs">
                <Link to="/hubs">
                  <img src={HubsIcon} alt="Hubs Icon" />
                  Hubs
                </Link>
              </Menu.Item>
            )}
            {ZEENOW_STORE && editPermission(modules.DISPATCH_DELIVERERS) && (
              <Menu.Item key="/entregadores">
                <Link to="/entregadores">
                  <img src={DeliverersIcon} alt="Deliverers Icon" />
                  Entregadores
                </Link>
              </Menu.Item>
            )}
            {ZEENOW_STORE && editPermission(modules.DISPATCH_EMBARKS) && (
              <Menu.Item key="/embarques">
                <Link to="/embarques">
                  <img src={ShippingsIcon} alt="Shippings Icon" />
                  Embarques
                </Link>
              </Menu.Item>
            )}
            {(editPermission(modules.DISPATCH_ORDERS) ||
              editPermission(modules.DISPATCH_ORDERS_VIEW)) && (
              <Menu.Item key="/expedicao">
                <Link to="/expedicao">
                  <img src={ExpeditionIcon} alt="Expedition Icon" />
                  Expedição
                </Link>
              </Menu.Item>
            )}
            {editPermission(modules.REPORTS_GROWTH) && (
              <SubMenu
                key="/relatorios"
                title={
                  <span>
                    <img src={ReportsIcon} alt="Reports Icon" />
                    <span>Relatórios</span>
                  </span>
                }
              >
                {ZEEDOG_STORE ? (
                  <SubMenu
                    key="/relatorios-faturamento"
                    title="Faturamento"
                    className="submenu-submenu"
                  >
                    <Menu.Item
                      className="menu-left"
                      key="/relatorios/faturamento/vendas"
                    >
                      <Link to="/relatorios/faturamento/vendas">Vendas</Link>
                    </Menu.Item>
                    <Menu.Item
                      className="menu-left"
                      key="/relatorios/faturamento/produtos"
                    >
                      <Link to="/relatorios/faturamento/produtos">
                        Produtos
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                ) : (
                  <SubMenu
                    key="/relatorios-faturamento"
                    title="Faturamento"
                    className="submenu-submenu"
                  >
                    <Menu.Item
                      className="menu-left"
                      key="/relatorios/faturamento/vendas"
                    >
                      <Link to="/relatorios/faturamento/vendas">Vendas</Link>
                    </Menu.Item>
                    <Menu.Item
                      className="menu-left"
                      key="/relatorios/faturamento/pagamentos"
                    >
                      <Link to="/relatorios/faturamento/pagamentos">
                        Pagamentos
                      </Link>
                    </Menu.Item>
                    <Menu.Item
                      className="menu-left"
                      key="/relatorios/faturamento/produtos"
                    >
                      <Link to="/relatorios/faturamento/produtos">
                        Produtos
                      </Link>
                    </Menu.Item>
                    <Menu.Item
                      className="menu-left"
                      key="/relatorios/faturamento/categorias-e-marcas"
                    >
                      <Tooltip
                        title="Categorias e Marcas"
                        placement="right"
                        overlayClassName="menu-tooltip"
                      >
                        <Link to="/relatorios/faturamento/categorias-e-marcas">
                          Categorias e Marcas
                        </Link>
                      </Tooltip>
                    </Menu.Item>
                    <Menu.Item
                      className="menu-left"
                      key="/relatorios/faturamento/influenciadores"
                    >
                      <Link to="/relatorios/faturamento/influenciadores">
                        Influenciadores
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                )}
                {ZEENOW_STORE && (
                  <SubMenu
                    key="/relatorios-conversao-aquisicao"
                    title={
                      <Tooltip
                        title="Conversão e Aquisição"
                        placement="right"
                        overlayClassName="menu-tooltip"
                      >
                        <span style={{ display: 'inline-block', width: 107 }}>
                          Conversão e Aquisição
                        </span>
                      </Tooltip>
                    }
                    className="submenu-submenu"
                  >
                    <Menu.Item
                      className="menu-left"
                      key="/relatorios/conversao-e-aquisicao/clientes"
                    >
                      <Link to="/relatorios/conversao-e-aquisicao/clientes">
                        Clientes
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                )}
                {ZEENOW_STORE && (
                  <SubMenu
                    key="/relatorios-operacional"
                    title="Operacional"
                    className="submenu-submenu"
                  >
                    <Menu.Item
                      className="menu-left"
                      key="/relatorios/operacional/tempos"
                    >
                      <Link to="/relatorios/operacional/tempos">Tempos</Link>
                    </Menu.Item>
                    <Menu.Item
                      className="menu-left"
                      key="/relatorios/operacional/cancelamentos"
                    >
                      <Link to="/relatorios/operacional/cancelamentos">
                        Cancelamentos
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                )}
                {ZEENOW_STORE && (
                  <Menu.Item className="menu-left" key="/relatorios/zpar">
                    <Link to="/relatorios/zpar">ZPAR</Link>
                  </Menu.Item>
                )}
                {ZEENOW_STORE ? (
                  <Menu.Item className="menu-left" key="/relatorios/nps">
                    <Link to="/relatorios/nps">NPS</Link>
                  </Menu.Item>
                ) : (
                  <SubMenu
                    key="/relatorios-nps"
                    title="NPS"
                    className="submenu-submenu"
                  >
                    <Menu.Item
                      className="menu-left"
                      key="/relatorios/nps/ecommerce"
                    >
                      <Link to="/relatorios/nps/ecommerce">NPS E-commerce</Link>
                    </Menu.Item>
                    <Menu.Item
                      className="menu-left"
                      key="/relatorios/nps/varejo"
                    >
                      <Link to="/relatorios/nps/varejo">NPS Varejo</Link>
                    </Menu.Item>
                  </SubMenu>
                )}
              </SubMenu>
            )}
            {editPermission(modules.USERS) && (
              <Menu.Item key="/usuarios">
                <Link to="/usuarios">
                  <img src={UsersIcon} alt="Users Icon" />
                  Usuários
                </Link>
              </Menu.Item>
            )}
          </Menu>
        </Sider>
        <Layout style={{ padding: '0 0 0 1px' }}>
          <Content
            style={{
              background: '#fff',
              margin: 0,
              minHeight: 280,
              position: 'relative'
            }}
          >
            {children}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  ) : (
    <Spin
      style={{
        background: 'rgba(255, 255, 255, 0.8)',
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 10,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    />
  );
};

MainLayout.defaultProps = {
  children: []
};

MainLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default MainLayout;
