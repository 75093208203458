const productsVideos = [
  {
    title: 'Guia para Cachorros Airleash Carbon',
    url: 'https://d17lu9slax0fqq.cloudfront.net/videos/Airleash_Carbon_450.mp4',
    poster: 'https://d25qvdyh5ydvzo.cloudfront.net/g2bjzsfxwolpy52d8126ozjxaxtf'
  },
  {
    title: 'Guia para Cachorros Airleash Cali Dos',
    url: 'https://d17lu9slax0fqq.cloudfront.net/videos/Airleash_Cali_450.mp4',
    poster: 'https://d25qvdyh5ydvzo.cloudfront.net/4pdwf812d7arp6i7qr16cyparh2c'
  },
  {
    title: 'Guia para Cachorros Airleash Blue',
    url: 'https://d17lu9slax0fqq.cloudfront.net/videos/Airleash_Blue_450.mp4',
    poster: 'https://d25qvdyh5ydvzo.cloudfront.net/t1xhj37nyeai4y98ies9rwg4b6lj'
  },
  {
    title: 'Guia para Cachorros Airleash Kim',
    url: 'https://d17lu9slax0fqq.cloudfront.net/videos/Airleash_Kim_450.mp4',
    poster: 'https://d25qvdyh5ydvzo.cloudfront.net/ur54dw8jkv8hmepyz5umtoasx3ra'
  },
  {
    title: 'Guia para Cachorros Airleash Cali Uno',
    url:
      'https://d17lu9slax0fqq.cloudfront.net/videos/guia-air-leash-cachorros-cali-uno-zeedog-cachorro-pet-main-2.mp4',
    poster: 'https://d25qvdyh5ydvzo.cloudfront.net/hiyv0abt779g5cu8dq352idjcbjd'
  },
  {
    title: 'Guia para Cachorros Airleash Classic',
    url:
      'https://d17lu9slax0fqq.cloudfront.net/videos/Airleash_Classic_450.mp4',
    poster: 'https://d25qvdyh5ydvzo.cloudfront.net/0ta330i49lwo6vwqybk0mc0swyvj'
  },
  {
    title: 'Cama para Cachorros Air.Bed',
    url:
      'https://ozksgdmyrqcxcwhnbepg.supabase.co/storage/v1/object/public/assets/2225/8749d88a-e963-4389-bea3-a59432fa2eb4',
    poster:
      'https://d25qvdyh5ydvzo.cloudfront.net/nto767bj6csm8e8mpyuyq9bf1h92',
    default: true
  },
  {
    title: 'Comedouro para Cachorros Zee.Bowl Gotham',
    url:
      'https://d17lu9slax0fqq.cloudfront.net/videos/comedouro-para-cachorros-zeebowl-ajustavel-gotham-zeedog-pet-main-2.mp4',
    poster: 'https://d25qvdyh5ydvzo.cloudfront.net/vf6ze70pq4nafoiwbi33rk7ykk8z'
  },
  {
    title: 'Comedouro para Cachorros Zee.Bowl Bordeau',
    url:
      'https://d17lu9slax0fqq.cloudfront.net/videos/comedouro-para-cachorros-zeebowl-ajustavel-bordeau-zeedog-pet-main-2.mp4',
    poster: 'https://d25qvdyh5ydvzo.cloudfront.net/cvr6quep90x9mrwn7h7t6h6fpxlt'
  },
  {
    title: 'Guia para Cachorros Padrão',
    url:
      'https://d17lu9slax0fqq.cloudfront.net/videos/pdp/zeedog-leash-hook-guide.mp4',
    poster:
      'https://d25qvdyh5ydvzo.cloudfront.net/vuczis9innd3q38c5k998g2srecg',
    default: true
  },
  {
    title: 'Peitoral para Cachorros H',
    url:
      'https://deco-sites-assets.s3.sa-east-1.amazonaws.com/zeedog/00574877-e3ee-429a-92bd-faede8ad4928/brain-h-harness-main-2.mp4',
    poster:
      'https://d25qvdyh5ydvzo.cloudfront.net/7kuc921p8rl2bm9zkfcalxp3xtvw',
    default: true
  },
  {
    title: 'Capa para Galaxy Tag',
    url:
      'https://deco-sites-assets.s3.sa-east-1.amazonaws.com/zeedog/2fe9e53c-25d2-4a7a-99dd-5c6cda39e94c/galaxy-tag-holder-lime-zeedog-pet-main-2.mp4',
    poster:
      'https://d25qvdyh5ydvzo.cloudfront.net/3fvw920veyaqg5cerlchb2hq0e7a',
    default: true
  },
  {
    title: 'Capa para AirTag',
    url:
      'https://d17lu9slax0fqq.cloudfront.net/videos/airtag-holder-jelly-zeedog-pet-main-2.mp4',
    poster:
      'https://d25qvdyh5ydvzo.cloudfront.net/ppz4r3dw8761u67kahi0u3m9nsox',
    default: true
  },
  {
    title: 'Guia Longa para Cachorros',
    url:
      'https://deco-sites-assets.s3.sa-east-1.amazonaws.com/zeedog/c4db7cf9-1b76-4ac6-9085-e66dbc7759d1/cmyk-long-leash-main-2.mp4',
    poster:
      'https://d25qvdyh5ydvzo.cloudfront.net/k1vlvwptr4lk0piueu7uo5x0yda7',
    default: true
  },
  {
    title: 'Brinquedo para Cachorros Marvel O Coisa',
    url:
      'https://deco-sites-assets.s3.sa-east-1.amazonaws.com/zeedog/f5ac03e4-cece-40d4-88e6-d98bb2f08e6a/how-to-cabeca-coisa.mp4',
    poster: 'https://d25qvdyh5ydvzo.cloudfront.net/88sjawqsg9ia49pljfr0wr5yri9n'
  },
  {
    title: 'Brinquedo para Cachorros Marvel Mão do Hulk',
    url:
      'https://deco-sites-assets.s3.sa-east-1.amazonaws.com/zeedog/c86a687a-fa83-4bae-af15-80ed4a83e9b4/how-to-mao-hulk.mp4',
    poster: 'https://d25qvdyh5ydvzo.cloudfront.net/iyei9zyorurvbpmknz07kaje49ph'
  },
  {
    title: 'Peitoral para Cachorros Air Mesh',
    url:
      'https://d17lu9slax0fqq.cloudfront.net/videos/pdp/peitoral-para-cachorros-air-mesh-zeedog-cachorro-pet-main-2.mp4',
    poster:
      'https://d25qvdyh5ydvzo.cloudfront.net/u0loxwfcdlwfm29oohhzfw5uxkiv',
    default: true
  },
  // {
  //   title: 'Guia para cachorros Neopro',
  //   url:
  //     'https://d17lu9slax0fqq.cloudfront.net/videos/pdp/zeedog-leash-hook-guide.mp4',
  //   poster: 'https://d25qvdyh5ydvzo.cloudfront.net/eht9i9zyhwmwik35929q8g8kt2fn'
  // },
  {
    title: 'Peitoral para Cachorros Air Mesh Ajustável',
    url:
      'https://deco-sites-assets.s3.sa-east-1.amazonaws.com/zeedog/257cb23e-23f0-465b-805d-f9a299c5d1c6/peitoral-para-cachorros-air-mesh-brain-zeedog-pet-main-2.mp4',
    poster:
      'https://d25qvdyh5ydvzo.cloudfront.net/8rueaki3jy946i6ltf6n9p008qu8',
    default: true
  },
  {
    title: 'Guia para Cachorros NitLeash',
    url:
      'https://ozksgdmyrqcxcwhnbepg.supabase.co/storage/v1/object/public/assets/2225/889705e8-0198-43e4-a45e-42303c4c1009',
    poster:
      'https://d25qvdyh5ydvzo.cloudfront.net/ptl2al550yh2wojohfeo7tflxzp4',
    default: true
  },
  {
    title: 'Porta-Saquinhos Higiênicos Preto Zee.Dog',
    url:
      'https://ozksgdmyrqcxcwhnbepg.supabase.co/storage/v1/object/public/assets/230/ee3e38f3-6386-4482-893a-51fc41aefbdc',
    poster:
      'https://d25qvdyh5ydvzo.cloudfront.net/q54erwp0hbjfnag7s850yux3yjq7',
    default: true
  },
  {
    title: 'Porta-Saquinhos Higiênicos Transparente Zee.Dog',
    url:
      'https://ozksgdmyrqcxcwhnbepg.supabase.co/storage/v1/object/public/assets/230/6b947c6e-99fa-49ea-8f3b-2eb35fc80e89',
    poster:
      'https://d25qvdyh5ydvzo.cloudfront.net/1rvjwlwth1b59gpl1wfgih0vjhwe',
    default: true
  },
  {
    title: 'Refil de Saquinhos Higiênicos Compostável Zee.Dog',
    url:
      'https://d17lu9slax0fqq.cloudfront.net/videos/zeedog-cachorros-saquinhos-higienicos-laranja-main-2.mp4',
    poster: 'https://d25qvdyh5ydvzo.cloudfront.net/nsurh1i7q1iiv5jses99y0pqs35l'
  },
  {
    title: 'Capa de Chuva para Cachorros Oatmeal',
    url:
      'https://d17lu9slax0fqq.cloudfront.net/videos/pdp/zeedog-capa-de-chuva-raincoat-_oatmeal_450x450.mp4',
    poster: 'https://d25qvdyh5ydvzo.cloudfront.net/7ag8jnoh4b8cyqr57usmiz3hr6eq'
  },
  {
    title: 'Guia com Amortecedor para Cachorros',
    url:
      'https://deco-sites-assets.s3.sa-east-1.amazonaws.com/zeedog/1a1fa573-9e43-4d5c-8498-9da0277690d2/brain-ruff-leash-main-2.mp4',
    poster:
      'https://d25qvdyh5ydvzo.cloudfront.net/jpyc53htugmoeaw19f707azgsjfs',
    default: true
  },
  {
    title: 'Guia com Amortecedor 2.0 para Cachorros Bordeau',
    url:
      'https://s3-sa-east-1.amazonaws.com/zee.dog/videos/guia-com-amortecimento-para-cachorros-bordeau-zeedog-cachorros-pet-main-02.mp4',
    poster: 'https://d25qvdyh5ydvzo.cloudfront.net/xk9rntu7lh0wo2vodztabr3ghzbx'
  },
  {
    title: 'Peitoral para Cachorros Softerwalk',
    url:
      'https://d17lu9slax0fqq.cloudfront.net/videos/softerwalk-howto-gotham.mp4',
    poster:
      'https://d25qvdyh5ydvzo.cloudfront.net/t2pnk7erk3hdvejsbizhr4dx4spy',
    default: true
  },
  {
    title: 'Capa Protetora Impermeável Zee.Bed 2.0',
    url:
      'https://s3-sa-east-1.amazonaws.com/zee.dog/videos/pdp/capa-de-cama-para-cachorros-waterproof-impermeavel-zee-bed-zeedog-cachorro-pet-main-2.mp4',
    poster: 'https://d25qvdyh5ydvzo.cloudfront.net/dnz2fg2u01tlg20z66r91ww55yda'
  },
  {
    title: 'Cama para Cachorros Zee.Bed Marvel',
    url:
      'https://deco-sites-assets.s3.sa-east-1.amazonaws.com/zeedog/19309b1f-34da-4206-a3e3-679698870a27/howto-marvel-zeebed.mp4',
    poster: 'https://d25qvdyh5ydvzo.cloudfront.net/r1ipawe7ugprmdiz2l61s7ynnbss'
  },
  {
    title: 'Cama para Cachorros Zee.Bed',
    url:
      'https://d17lu9slax0fqq.cloudfront.net/videos/pdp/cama-para-cachorros-zeebed-skull-zeedog-cachorro-pet-main-02.mp4',
    poster:
      'https://d25qvdyh5ydvzo.cloudfront.net/pzs6ay7ajfkrxx62a3f5b8geo19b',
    default: true
  },
  {
    title: 'Cama para Cachorros Zee.Bed Najima',
    url:
      'https://d17lu9slax0fqq.cloudfront.net/videos/pdp/zeedog-zeebed-bed-guide.mp4',
    poster: 'https://d25qvdyh5ydvzo.cloudfront.net/kki4nvols6p0glckwz82334zj86w'
  },
  {
    title: 'Tapete Higiênico para Cachorros Zee.Pad - 30 unidades',
    url:
      'https://ozksgdmyrqcxcwhnbepg.supabase.co/storage/v1/object/public/assets/230/fa619abd-fa81-41aa-bfaa-2cc0c80bfc1f',
    poster:
      'https://d25qvdyh5ydvzo.cloudfront.net/4fm53jstp8h5qrvbo768nfj1phoi',
    default: true
  },
  {
    title: 'Peitoral para Cachorros Anti Puxão',
    url:
      'https://data.decoassets.com/zeedog/7d719bc3-18c7-4102-b950-6bf6db72b73c/peitoral-soft-walk-cachorros-cinnamon-zeedog-pet-main-02.mp4',
    poster:
      'https://d25qvdyh5ydvzo.cloudfront.net/e5ntl6xdenrw489alsqswiid5spe',
    default: true
  }
];

export default productsVideos;
